import NProgress from 'nprogress';

const preloader = document.querySelector('#preloader');
const content = document.querySelector('#content');
const modal = document.querySelectorAll('.modal');
const cookies = document.getElementById('cookies');

NProgress.configure({ trickleRate: 0.0667, trickleSpeed: 100 });
NProgress.start();
setTimeout(() => {
  NProgress.done();
  window.scrollTo({
    top: 0,
  });
  if (preloader) {
    preloader.classList.add('-translate-y-screen');
  }
  if (content) {
    content.classList.remove('translate-y-screen');
    content.classList.remove('opacity-0');
    content.style.opacity = 1;
  }
  if (cookies) {
    cookies.style.opacity = 1;
  }
  if (modal) {
    modal.forEach((node) => {
      node.style.opacity = 1;
    });
  }
}, 2000);
